<template>
  <div class="columns is-vcentered is-centered">
    <div class="column is-6">
      <div v-show="sendMail" class="notification is-primary is-light">
        <strong>Link enviado!</strong><br />Basta clicar para redefinir sua
        senha
      </div>
      <div v-show="!sendMail">
        <h1 class="title">Esqueci minha senha</h1>
        <p class="subtitle">Enviaremos o link para redefinir senha</p>
        <form @submit.prevent="onSubmit">
          <div class="field">
            <label class="label">Email</label>
            <div class="control">
              <input
                v-model="user.email"
                class="input"
                type="email"
                placeholder="Email"
                required
              />
            </div>
          </div>

          <div class="level">
            <div class="field is-grouped">
              <div class="control">
                <button :disabled="request" class="button is-warning">
                  Enviar
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecoverPassword",
  components: {},
  data() {
    return { user: {}, request: false, sendMail: false };
  },
  created() {
    this.request = false;
    this.sendMail = false;
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        this.request = true;
        this.$http
          .post("auth/recover_password", this.user)
          .then((response) => {
            if (response.data == "ne") {
              this.$refs.form.setErrors({ email: "Email não cadastrado" });
              return;
            } else {
              this.request = false;
              this.sendMail = true;
              console.log("email enviado...");
            }
          })
          .catch((err) => {
            console.log(err);
          });
        this.$nextTick(() => {
          this.$refs.form.reset();
        });
      });
    },
  },
};
</script>
